import axios from 'axios';


export default axios.create({
    // baseURL: 'http://127.0.0.1:9000',
    baseURL: 'https://baseballapi.augurlabs.com',
    timeout: 300000,
    headers: {
        // 'Content-Type': 'application/json',
    }
});
