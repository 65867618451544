












































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DefaultVue extends Vue {
  // public load() {
  // }
  // private mounted() {
  //   this.load();
  // }
}
