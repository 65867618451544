













import { Component, Vue, Prop } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import UserService from "@/services/UserService";

@Component({
  components: {}
})
export default class LogoutView extends Vue {
  private logout() {
    (this as any).$userService.removeSelf();
    this.$router.push("login");
  }
}
