












































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BBService from "@/services/BBService";
import { Card } from "@/models/Card";

@Component({
  components: {},
})
export default class CardsView extends Vue {
  private bbs = new BBService();
  private cardList: Card[] = [];
  private cardFilters = {
    name: { value: "", keys: ["name"] },
  };
  private projectList = [];

  private loading = false;
  private currentPage = 1;
  private totalPages = 0;
  private selectedProject = null;

  private tradeSheet: any = {
    card: null,
    timestamp: null,
    direction: 1,
    price: null,
    quantity: null,
  };

  private projectName = "";

  private mounted() {
    this.getCards();
    this.getProjects();
  }

  private getCards() {
    this.loading = true;

    this.bbs.getCards().then((response) => {
      const cards = response.results;

      this.cardList = cards;
      this.loading = false;
    });
  }

  private getProject(project: any) {
    this.loading = true;

    this.bbs.getProject(project.id).then((response) => {
      const cards = response.results;

      this.cardList = cards;
      this.loading = false;
      this.selectedProject = project;
    });
  }

  private clearProject() {
    this.selectedProject = null;
    this.cardList = [];
    this.getCards();
  }

  private showTradeModal(card: Card) {
    this.tradeSheet.card = card;
    this.tradeSheet.timestamp = null;
    this.tradeSheet.directoin = 1;
    this.tradeSheet.price = null;
    this.tradeSheet.quantity = null;
    this.$bvModal.show("modal-trade");
  }

  private showCreateProjectModal() {
    this.projectName = "";
    this.$bvModal.show("modal-project");
  }

  private submitTrade() {
    this.bbs
      .submitTrade(
        this.tradeSheet.card.id,
        this.tradeSheet.timestamp,
        this.tradeSheet.direction,
        this.tradeSheet.quantity,
        this.tradeSheet.price
      )
      .then((response) => {
        this.$bvToast.toast("Transaction has been recorded!", {
          autoHideDelay: 3500,
          appendToast: false,
          noCloseButton: true,
          variant: "success",
          solid: false,
        });
      });
  }

  private getProjects() {
    this.bbs.getProjects().then((response) => {
      this.projectList = response.results;
    });
  }

  private createProject() {
    this.bbs.createProject(this.projectName).then((response) => {
      this.$bvToast.toast("Project has been created!", {
        autoHideDelay: 3500,
        appendToast: false,
        noCloseButton: true,
        variant: "success",
        solid: false,
      });
      this.getProjects();
    });
  }

  private assignToProject(projectId: string, cardId: string) {
    this.bbs.assignToProject(projectId, cardId).then((response) => {
      this.$bvToast.toast("Card has been assigned!", {
        autoHideDelay: 3500,
        appendToast: false,
        noCloseButton: true,
        variant: "success",
        solid: false,
      });
    });
  }
}
