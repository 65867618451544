import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import CardsView from '../views/CardsView.vue'
import TransactionsView from '../views/TransactionsView.vue'
import VaultView from '../views/VaultView.vue'
import LoginView from '../views/LoginView.vue';
import LogoutView from '../views/LogoutView.vue';
import UserService from '../services/UserService';


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'cards' },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/cards',
    name: 'cards',
    component: CardsView,
    meta: { requiresAuth: true, clear: true }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionsView,
    meta: { requiresAuth: true, clear: true }
  },
  {
    path: '/vault',
    name: 'vault',
    component: VaultView,
    meta: { requiresAuth: true, clear: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const userService = new UserService();


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userService.getSelf()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {

      if (to.meta.clear) {
        next();
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router
