import axios from "@/services/api";
import UserService from "@/services/UserService";

export default class BBService {

    private userService = new UserService();

    public async getCards() {
        try {
            const response = await axios.get(
                `/api/v1/cards/`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getTransactions() {
        try {
            const response = await axios.get(
                `/api/v1/transactions/`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getProject(projectId: string) {
        try {
            const response = await axios.get(
                `/api/v1/cards/?project=` + projectId,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getProjects() {
        try {
            const response = await axios.get(
                `/api/v1/projects/`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getVault() {
        try {
            const response = await axios.get(
                `/api/v1/vault/`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async submitTrade(id: string, timestamp: string, direction: number, quantity: number, price: number) {
        return await axios.post("/api/v1/transactions/", {
            id,
            timestamp,
            direction,
            quantity,
            price
        }, {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        });
    }

    public async createProject(name: string) {
        return await axios.post("/api/v1/projects/", {
            name,
            type: 'create'
        }, {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        });
    }

    public async assignToProject(projectId: string, cardId: string) {
        return await axios.post("/api/v1/projects/", {
            projectId,
            cardId,
            type: 'assign'
        }, {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        });
    }



    public async getData(symbol: any) {
        try {
            const response = await axios.get(
                `/api/v1/poll/?symbol=${symbol}`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async submitPoll(symbol: any, value: any) {
        return await axios.post("/api/v1/poll/", {
            symbol,
            value
        }, {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        });
    }


    private getHeaders() {
        return {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        };
    }
}
