
















































import { Component, Vue } from "vue-property-decorator";
import BBService from "@/services/BBService";

@Component({
  components: {},
})
export default class TransactionsView extends Vue {
  private bbs = new BBService();
  private transactionList: any = [];

  private loading = false;

  private mounted() {
    this.getCards();
  }

  private getCards() {
    this.loading = true;

    this.bbs.getTransactions().then((response) => {
      const cards = response.results;

      this.transactionList = cards;
      this.loading = false;
    });
  }
}
