import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios';
import UserService from "@/services/UserService"
import SmartTable from 'vuejs-smart-table'
import numFormat from 'vue-filter-number-format';
import numeral from 'numeral';


Vue.config.productionTip = false;

Vue.prototype.$userService = new UserService();

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(SmartTable)

Vue.filter('numFormat', numFormat(numeral));




new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
